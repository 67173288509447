<template>
  <div class="dizme_tm_section" id="about">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/${dark ? 2 : 1}.jpg`" alt=""/>
              <div class="numbers year">
                <div class="wrapper">
                  <h3>
                    <VueJsCounter end="5"></VueJsCounter>
                  </h3>
                  <span class="name">Years of<br/>Success</span>
                </div>
              </div>
              <div class="numbers project">
                <div class="wrapper">
                  <h3 style="display: flex">
                    <VueJsCounter as="span" end="150"></VueJsCounter>+
                  </h3>
                  <span class="name">Total<br/>Projects</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <span>I'm a Software Engineer</span>
              <h5>I focus on designing and developing solutions after thorough and meticulous planning.</h5>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                As you explore my portfolio, you'll discover a versatile and creative Software Engineer with over five
                years of experience in the IT field. I possess a strong understanding of data organization, algorithms,
                and coding methodologies, coupled with exceptional problem-solving abilities. I'm a quick learner and
                can easily adapt to new systems. I've contributed to various projects of different difficulty levels,
                utilizing my skills to provide effective solutions. My experience extends across both the private and
                public sectors, encompassing industries such as Health, Education, Fintech, and e-commerce.
              </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="#contact"><span>Hire Me</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/1.png" alt=""/>
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/about/2.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsCounter from "vue-js-counter";

export default {
  name: "AboutComponent",
  components: {VueJsCounter},
  props: {
    dark: {type: Boolean},
  },
};
</script>
