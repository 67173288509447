<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_subscribe">
      <div class="container">
        <div class="inner">
          <div class="background">
            <div class="dots" data-img-url="/img/subscribe/dots.jpg"></div>
            <div class="overlay"></div>
          </div>
          <div v-if="!subscribed" class="form-div">
            <div class="content">
              <div class="left wow fadeInLeft" data-wow-duration="1s">
                <span class="subtitle">Subscribe Now</span>
                <h3 class="title">Get My Newsletter</h3>
                <p class="text">
                  Get latest news, updates, tips and trics in your inbox
                </p>
              </div>
              <div class="right wow fadeInRight" data-wow-duration="1s">
                <div class="field mb-10">
                  <input v-model="name" type="text" placeholder="Enter Your First Name"/>
                </div>
                <div class="field">
                  <input v-model="email" type="text" placeholder="Your email here"/>
                  <input @click="submitNewsLetter" type="submit" value="Send Now"/>
                </div>
              </div>
            </div>
          </div>
          <div v-if="subscribed" class="added-div">
            <div class="content">
              <h3>
                <span><i class="icon icon-check"></i></span>
                Thank you for Subscribing, expect nothing but the best.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080'; // Your frontend URL
axios.defaults.headers.common['Content-Type'] = 'application/json';
export default {
  name: "SubscribeComponent",
  components: {},
  data: function () {
    return {
      name: null,
      email: null,
      subscribed: false
    };
  },
  methods: {
    async submitNewsLetter() {
      try {
        await axios.post(
            'https://backend.akandeha.com/newsletter',
            {email: this.email, first_name: this.name,}
        ).then((res)=>{
          if(res.status === 200){
            this.subscribed = true;
          }
        });
      } catch (e) {
        alert("Please try again")
      }
    },
  }
};
</script>
