<template>
  <div class="dizme_tm_section" id="process">
    <div class="dizme_tm_process">
      <div class="container">
        <div class="list">
          <ul>
            <li class="wow fadeInUp" data-wow-duration="1s">
              <div class="list_inner">
                <div class="icon">
                  <span v-html="dark ? pixelPerfectDark : pixelPerfectLight">
                  </span>
                </div>
                <div class="title">
                  <h3>Effective Communication</h3>
                </div>
                <div class="text">
                  <p>
                    Mastering the art of effective communication allows me to bridge the gap between complex technical
                    concepts and non-technical stakeholders.
                  </p>
                </div>
              </div>
            </li>
            <li
                class="wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="list_inner">
                <div class="icon">
                  <span v-html="dark ? highQualityDark : highQualityLight">
                  </span>
                </div>
                <div class="title">
                  <h3>Expertise</h3>
                </div>
                <div class="text">
                  <p>
                    My proficiency in various programming languages, frameworks, and tools empowers me to bring
                    innovative solutions to life.
                  </p>
                </div>
              </div>
            </li>
            <li
                class="wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
            >
              <div class="list_inner">
                <div class="icon">
                  <span v-html="dark ? awesomeIdeaDark : awesomeIdeaLight">
                  </span>
                </div>
                <div class="title">
                  <h3>Problem Solving</h3>
                </div>
                <div class="text">
                  <p>
                    Through creative thinking and analytical skills, I approach each obstacle as an opportunity to
                    innovate and find robust solutions.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  awesomeIdeaDark,
  awesomeIdeaLight,
  highQualityDark,
  highQualityLight,
  pixelPerfectDark,
  pixelPerfectLight,
} from "@/svg";

export default {
  name: "ProcessComponent",
  data() {
    return {
      pixelPerfectLight,
      highQualityDark,
      awesomeIdeaDark,
      pixelPerfectDark,
      awesomeIdeaLight,
      highQualityLight,
    };
  },
  components: {},
  props: {
    dark: {type: Boolean},
  },
};
</script>
