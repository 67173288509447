<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_skills">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div
                class="dizme_tm_main_title wow fadeInUp"
                data-wow-duration="1s"
                data-align="left"
            >
              <span>Code is Life</span>
              <h3>I Develop Skills Regularly to Keep Me Update</h3>
              <p>
                Technology is always changing, and I know it's important to keep learning. I make sure to regularly
                improve my skills so I can stay updated with the latest in software engineering. Learning new things
                helps me do better in my work and contribute more to projects. I believe that getting better and
                learning new things is very important, and I always try my best to do that.
              </p>
            </div>
            <div class="dodo_progress wow fadeInUp" data-wow-duration="1s">
              <div
                  class="progress_inner skillsInner___"
                  data-value="75"
                  data-color="#f75023">
                <span>
                  <span class="label">Java</span>
                  <span class="number">75%</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in"></div>
                  </div>
                </div>
              </div>
              <div
                  class="progress_inner skillsInner___"
                  data-value="85"
                  data-color="#f75023">
                <span>
                  <span class="label">PHP</span>
                  <span class="number">85%</span>
                </span>
                <div class="background">
                  <div class="bar">
                    <div class="bar_in"></div>
                  </div>
                </div>
              </div>
              <div
                  class="progress_inner skillsInner___"
                  data-value="95"
                  data-color="#1cbe59">
                <span
                ><span class="label">Go</span
                ><span class="number">95%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in"></div>
                  </div>
                </div>
              </div>
              <div
                  class="progress_inner skillsInner___"
                  data-value="95"
                  data-color="#1cbe59">
                <span
                ><span class="label">Vue</span
                ><span class="number">95%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in"></div>
                  </div>
                </div>
              </div>
              <div
                  class="progress_inner skillsInner___"
                  data-value="95"
                  data-color="#1cbe59">
                <span
                ><span class="label">Flutter</span
                ><span class="number">95%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in"></div>
                  </div>
                </div>
              </div>
              <div
                  class="progress_inner skillsInner___"
                  data-value="65"
                  data-color="#8067f0"
              >
                <span
                ><span class="label">Figma</span
                ><span class="number">65%</span></span
                >
                <div class="background">
                  <div class="bar">
                    <div class="bar_in"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <img :src="`/img/skills/fed.png`" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkillComponent",
  components: {},
  props: {
    dark: {type: Boolean},
  },
};
</script>
