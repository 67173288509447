<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div
          class="background"
          :data-img-url="`/img/slider/${dark ? 2 : 1}.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="hello">
              <h3 class="orangeText">Hello, I'm</h3>
            </div>
            <div class="name">
              <h3>Habib Akande</h3>
            </div>
            <div class="job">
              <p>
                A <span class="greenText">Software Engineer</span> in
                <span class="purpleText">Cyprus</span>
              </p>
            </div>
            <div class="text">
              <p>
                I'm a software engineer with over 5 years of experience designing and developing bespoke applications
                for my clients. I am very dedicated to always bringing the best with me wherever and whenever.
              </p>
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#about"><span>About Me</span></a>
              </div>
              <div class="social">
                <ul>
                  <li>
                    <a href="https://twitter.com/tobiasamour"><i class="icon-twitter-1"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/akandeha/"><i class="icon-linkedin-1"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-github-1"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/img/slider/me.png" alt=""/>
              <span class="skills github anim_moveBottom" v-html="github"></span>
              <span class="skills java anim_moveBottom" v-html="java"></span>
              <span class="skills figma anim_moveBottom" v-html="figma"></span>
              <span class="skills php anim_moveBottom" v-html="php"></span>
              <span class="skills flutter anim_moveBottom" v-html="flutter"></span>
              <span class="skills laravel anim_moveBottom" v-html="laravel"></span>
              <span class="skills vueSvg anim_moveBottom" v-html="vueSvg"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {figma, github, mouse, mouseDark, java, php, laravel, flutter, vueSvg} from "@/svg";

export default {
  name: "HomeComponent",
  data() {
    return { figma, mouse, mouseDark,github,java,php,laravel,flutter,vueSvg};
  },
  props: {
    dark: {type: Boolean},
  },
};
</script>
