<template>
  <div class="dizme_tm_section" id="contact">
    <div class="dizme_tm_contact">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Contact Me</span>
          <h3>I Want To Hear From You</h3>
          <p>
            Please fill out the form on this section to contact with me or Whatsapp</p>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <ul>
              <li>
                <div class="list_inner">
                  <div class="icon orangeBackground">
                    <i class="icon-location orangeText"></i>
                  </div>
                  <div class="short">
                    <h3>Address</h3>
                    <span>Cyprus</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="icon greenBackground">
                    <i class="icon-mail-1 greenText"></i>
                  </div>
                  <div class="short">
                    <h3>Email</h3>
                    <span><a href="#">akandeha43@gmail.com</a></span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="icon purpleBackground">
                    <i class="icon-phone purpleText"></i>
                  </div>
                  <div class="short">
                    <h3>Phone</h3>
                    <span>+90 539 101 4281</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div v-if="!contacted" class="fields">
              <form
                  class="contact_form"
                  id="contact_form"
                  autocomplete="off"
                  v-on:submit.prevent="submitContact"
              >
                <div
                    class="returnmessage"
                    data-success="Your message has been received, We will contact you soon."
                ></div>
                <div class="empty_notice">
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input v-model="name" id="name" type="text" placeholder="Your Name"/>
                    </li>
                    <li>
                      <input v-model="email" id="email" type="text" placeholder="Your Email"/>
                    </li>
                    <li>
                      <input
                          id="phone"
                          type="number"
                          v-model="phone"
                          placeholder="Your Phone"
                      />
                    </li>
                    <li>
                      <input v-model="subject" id="subject" type="text" placeholder="Subject"/>
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                      id="message"
                      v-model="message"
                      placeholder="Write your message here"
                  ></textarea>
                </div>
                <div class="dizme_tm_button">
                  <a v-on:click.prevent="submitContact" type="submit" id="send_message" ><span>Submit Now</span></a>
                </div>
              </form>
            </div>
            <div v-if="contacted">
              <h4>Thank you for contacting me, I will be in touch very soon</h4>
            </div>
          </div>
          <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
            <img src="img/brushes/contact/2.png" alt=""/>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="img/brushes/contact/1.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8080'; // Your frontend URL
axios.defaults.headers.common['Content-Type'] = 'application/json';
export default {
  name: "ContactComponent",
  components: {},
  data: function () {
    return {
      name: null,
      phone: null,
      email: null,
      message: null,
      subject: null,
      contacted: false
    }
  },
  methods: {
    async submitContact() {
      const data = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        message: this.message,
        subject: this.subject
      };
      const url = "https://backend.akandeha.com/contact";
      try {
        await axios.post(url, data)
            .then((res) => {
              if (res.status === 200) {
                this.contacted = true;
              }
            });
      } catch (e) {
        alert("Please try again")
      }
    }
  }
};
</script>
